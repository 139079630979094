import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const Image = styled(Plaatjie)`
  border-radius: 25px;
  overflow: hidden;

  @media (min-width: 1200px) {
    height: 300px;
  }

  @media (max-width: 1199.98px) {
    height: 250px;
  }

  @media (max-width: 991.98px) {
    height: 200px;
  }

  @media (max-width: 767.98px) {
    height: 150px;
  }

  @media (max-width: 575.98px) {
    max-width: 200px;
    margin: 0 auto;
  }
`

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};

  @media (min-width: 1200px) {
    min-height: 459px;
    height: 40vh;
  }
`

const Content = styled(ParseContent)`
  & h1 {
    padding-top: 0 !important;
    color: ${({ theme }) => theme.color.light};

    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.larger};
      padding-top: 12rem;
    }

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }
`

const BannerSmall: React.FC<BannerSmallProps> = ({ fields }) => {
  return (
    <Section className="position-relative pt-5">
      <div className="container h-100 py-5">
        <div className="row h-100 d-flex align-items-center">
          <div className="col-sm-8 h-100 d-flex align-items-center">
            <Content content={fields.description} />
          </div>
          <div className="col-sm-4 h-100 d-flex align-items-center mt-sm-0 mt-4">
            <Image image={fields.image} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default BannerSmall
